<script setup>
import {onMounted, ref} from "vue";
import {useQuasar} from "quasar";

const themeToggleBtn = ref()
const themeToggleDarkIcon = ref()
const themeToggleLightIcon = ref()

function isDarkMode() {
    return (localStorage.getItem('color-theme') === 'dark' || (!('color-theme' in localStorage) &&
        window.matchMedia('(prefers-color-scheme: dark)').matches))
}

onMounted(() => {
    const $q = useQuasar()
// Change the icons inside the button based on previous settings
    if (isDarkMode()) {
        themeToggleDarkIcon.value.classList.toggle('tw-hidden');
    } else {
        themeToggleLightIcon.value.classList.toggle('tw-hidden');
        document.documentElement.classList.remove('tw-dark');
    }

    themeToggleBtn.value.addEventListener('click', function() {


        // toggle icons inside button
        themeToggleDarkIcon.value.classList.toggle('tw-hidden');
        themeToggleLightIcon.value.classList.toggle('tw-hidden');

        // if set via local storage previously
        if (localStorage.getItem('color-theme')) {
            if (localStorage.getItem('color-theme') === 'light') {
                document.documentElement.classList.add('tw-dark');
                $q.dark.set(true) // or false or "auto"
                localStorage.setItem('color-theme', 'dark');
            } else {
                $q.dark.set(false) // or false or "auto"
                document.documentElement.classList.remove('tw-dark');
                localStorage.setItem('color-theme', 'light');
            }

            // if NOT set via local storage previously
        } else {
            if (document.documentElement.classList.contains('tw-dark')) {
                $q.dark.set(false)
                document.documentElement.classList.remove('tw-dark');
                localStorage.setItem('color-theme', 'light');
                return;
            }
            $q.dark.set(true) // or false or "auto"
            document.documentElement.classList.add('tw-dark');
            localStorage.setItem('color-theme', 'dark');
        }

    });
})
</script>

<template>
    <button ref="themeToggleBtn" id="theme-toggle" type="button"
            class="tw-text-gray-500 dark:tw-text-gray-400 hover:tw-bg-gray-100 dark:hover:tw-bg-gray-500
            focus:tw-outline-none focus:tw-ring-4 focus:tw-ring-gray-800 dark:tw-focus:ring-gray-700 tw-rounded-lg
            tw-text-sm tw-p-1.5 tw-ring-2 tw-ring-slate-600 dark:tw-ring-gray-500 md:tw-p-2.5 tw-ml-3">
        <svg ref="themeToggleLightIcon" class="tw-w-5 tw-h-5" fill="currentColor"
             viewBox="0 0 20 20"
             xmlns="http://www.w3.org/2000/svg"><path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path></svg>
        <svg ref="themeToggleDarkIcon" class="tw-w-5 tw-h-5" fill="currentColor"
             viewBox="0 0 20 20"
             xmlns="http://www.w3.org/2000/svg"><path d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
        <q-tooltip class="text-body2">
            Dark mode / Light mode
        </q-tooltip>
    </button>
</template>

